<script>
export default {
name: "PscDashboard"
}
</script>

<template>
  <div class="container-fluid px-4">
    <nav class="navbar navbar-expand-lg mt-2" id="ModelNav">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
              aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'PscDashboardGeneral'}">
              <span style="height: 100%">General</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'PscYearlyOverview'}">
              <span>PSC Yearly Overview</span>
            </router-link>
          </li>
          <li class="nav-item" >
            <router-link class="nav-link" :to="{ name: 'PscMonthlySummary'}">
              <span>PSC Monthly Summary</span>
            </router-link>
          </li>
          <li class="nav-item" >
            <router-link class="nav-link" :to="{ name: 'PscInspectionByCountry'}">
              <span>PSC Inspection By Country</span>
            </router-link>
          </li>
          <li class="nav-item" >
            <router-link class="nav-link" :to="{ name: 'PscInspectionByMOU'}">
              <span>PSC Inspection By MOU</span>
            </router-link>
          </li>
          <li class="nav-item" >
            <router-link class="nav-link" :to="{ name: 'PscDeficiencySummary'}">
              <span>PSC Deficiency</span>
            </router-link>
          </li>
          <li class="nav-item" >
            <router-link class="nav-link" :to="{ name: 'PscMonthlyCommittee'}">
              <span>PSC Deficiency Summary</span>
            </router-link>
          </li>
          <li class="nav-item" >
            <router-link class="nav-link" :to="{ name: 'PscVesselPerformance'}">
              <span>Vessel’s Performance (Nbr Deficiency)</span>
            </router-link>
          </li>
        </ul>
      </div>
    </nav>

    <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>

    <router-view />
  </div>
</template>

<style scoped lang="scss">
.nav > li {
  margin: .5rem 0;
  padding: .5rem;
  border-radius: 5px;
  height: 100%;

  &:hover, &:hover * {
    background-color: white;
    color: #d3313c;
  }
}

.e-btn-grp > li {
  list-style: none;
  font-size: 13px;
  padding: 4px;
  border-radius: 3px;
  color: #d3313c;
  cursor: pointer;

  &:hover {
    background-color: #fbeaec;
  }

  * {
    color: #d3313c;
  }
}

#ModelNav {
  padding: 0;

  li > a {
    padding: .5rem 2rem;
    color: #435464;
    height: 100%;
    text-align: center;
  }

  li:hover a {
    border-bottom: 2px solid #d3313c;
    color: #d3313c;
  }
  .nav-link span {
    font-size: 14px;
  }
}

.router-link-active {
  border-bottom: 2px solid #d3313c;
  color: #d3313c;
  font-weight: bold;

  * {
    color: #d3313c;
  }
}

table td {
  padding: 2px !important;
}
</style>
